import { useMemo } from 'react'

import { useCmsContext } from '../../contexts/cms'

export type ProductCategory = cms.CMSData['products-categories'][string]

export const useProductsCategories = (): {
  table: Map<string, ProductCategory>
  list: Array<ProductCategory>
} => {
  const { cmsData } = useCmsContext()

  return useMemo(() => {
    const entities = Object.entries(cmsData['products-categories']).map<
      [string, ProductCategory]
    >(([key, val]) => [val?.identifier ?? key, val as ProductCategory])

    return {
      table: new Map(entities),
      list: entities.map(([, entity]) => entity),
    }
  }, [cmsData])
}

import '../styles/components/product-tile.scss'
import { Link, navigate } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { BsArrowRight } from 'react-icons/bs'

import { useLabelsAndInternationalization } from '../hooks/cms/use-labels-and-internationalization'
import type { Product } from '../hooks/cms/use-products'
import { useProductsCategories } from '../hooks/cms/use-products-categories'
import { useTags } from '../hooks/cms/use-tags'
import { useHrefMaker } from '../hooks/use-href-maker'
import { useImages } from '../hooks/use-images'
import { Button } from './button'
import { OverflowIndicator } from './overflow-indicator'
import { Tag } from './tag'

export interface ProductTileProps {
  product: Product
  forSlider?: boolean
  selectedTags?: { [key: string]: boolean }
}

export const ProductTile: React.FC<ProductTileProps> = ({
  product,
  forSlider,
  selectedTags,
}) => {
  const { getImage } = useImages()
  const { table: tags } = useTags()
  const { table: categories } = useProductsCategories()
  const image = getImage(product.preview?.image)?.fluid
  const category = categories.get(product.category)
  const labels = useLabelsAndInternationalization()
  const makeHref = useHrefMaker()

  const checkIfTagSelected = (tagName: string) =>
    selectedTags ? selectedTags[tagName] : false

  return (
    <div
      className="product-tile"
      onClick={() => navigate(makeHref(`/products/${product.identifier}`))}
    >
      <div>
        {image && (
          <Img
            className={`product-tile__image${forSlider ? '-slider' : ''}`}
            fluid={image}
          />
        )}
      </div>
      <div>
        <Link
          className="product-tile__category"
          to="/products"
          state={{
            category: category?.identifier,
          }}
          onClick={e => e.stopPropagation()}
        >
          {category?.title} <BsArrowRight />
        </Link>
        <span className="product-tile__name">{product.name}</span>
        <h4 className="product-tile__heading">{product.preview?.heading}</h4>
        <p className="product-tile__description">
          {product.preview?.description}
        </p>
        {product.tags && (
          <div className="product-tile__tags">
            <OverflowIndicator>
              {product.tags.map(identifier => (
                <Tag
                  selected={checkIfTagSelected(identifier)}
                  key={identifier}
                  onClick={e => {
                    e.stopPropagation()
                    navigate(makeHref(`/products`), {
                      state: {
                        tag: identifier,
                      },
                    })
                  }}
                >
                  {tags.get(identifier)?.title}
                </Tag>
              ))}
            </OverflowIndicator>
          </div>
        )}
        <div className="product-tile__cta">
          <Button
            icon={<BsArrowRight />}
            onClick={e => {
              e.stopPropagation()
              navigate(makeHref(`/products/${product.identifier}`))
            }}
          >
            {labels.strings.learnMoreButton}
          </Button>
        </div>
      </div>
    </div>
  )
}

import { useMemo } from 'react'

import { useCmsContext } from '../../contexts/cms'

export type Product = cms.CMSData['products'][string]

export const useProducts = (): {
  table: Map<string, Product>
  list: Array<Product>
} => {
  const { cmsData } = useCmsContext()

  return useMemo(() => {
    const entities = Object.entries(cmsData.products).map<[string, Product]>(
      ([key, val]) => [val?.identifier ?? key, val as Product]
    )

    return {
      table: new Map(entities),
      list: entities
        .map(([, entity]) => ({
          ...entity,
          order: typeof entity.order === 'number' ? entity.order : Infinity,
        }))
        // Sort by order ascending
        .sort((a, b) => a.order - b.order),
    }
  }, [cmsData])
}

import '../styles/templates/products.scss'

import { omit } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'

import { PAGE_NAME } from '../../config/cms'
import { Layout } from '../components/layout'
import { PageHeading } from '../components/page-heading'
import { PageSubheading } from '../components/page-subheading'
import { ProductTile } from '../components/product-tile'
import { Tag } from '../components/tag'
import { usePageData } from '../hooks/cms/use-page'
import { useProducts } from '../hooks/cms/use-products'
import { useProductsCategories } from '../hooks/cms/use-products-categories'
import { useTags } from '../hooks/cms/use-tags'
import { win } from '../utils/monads'
import { PageComponentFactory } from '../utils/pages'
import { classNameMap } from '../utils/tsx'

const TEMPLATE_NAME = PAGE_NAME.Products

const ProductsPage = PageComponentFactory(() => {
  const { landingSection, strings } = usePageData(PAGE_NAME.Products)

  const categories = useProductsCategories()
  const locationState = win.history.state
  const tags = useTags()
  const products = useProducts()

  const uniqueProductsTags = new Set(
    products.list.flatMap(element => element.tags)
  )

  const tagsToDisplay = tags.list.filter(tag =>
    uniqueProductsTags.has(tag.identifier)
  )

  const [selectedCategory, setSelectedCategory] = useState('all')
  const [selectedTags, setSelectedTags] = useState<Record<string, boolean>>({})

  const categoriesItems = useMemo(() => {
    return categories.list.map(category => (
      <div
        role="button"
        key={category.identifier}
        onClick={() => setSelectedCategory(category.identifier)}
        className={classNameMap({
          selected: selectedCategory === category.identifier,
        })}
      >
        {category.title}
        <span>
          {
            products.list.filter(
              product => product.category === category.identifier
            ).length
          }
        </span>
      </div>
    ))
  }, [categories.list, products.list, selectedCategory])

  const filteredProducts = useMemo(
    () =>
      products.list.filter(
        product =>
          (selectedCategory === 'all' ||
            product.category === selectedCategory) &&
          (Object.keys(selectedTags).length === 0 ||
            Object.keys(selectedTags).every(x => product.tags?.includes(x)))
      ),
    [products.list, selectedCategory, selectedTags]
  )

  useEffect(() => {
    if (locationState?.tag) {
      setSelectedTags(currentSelectedTags => {
        if (currentSelectedTags[locationState.tag]) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [locationState.tag]: _, ...rest } = currentSelectedTags
          return rest
        } else {
          return {
            ...currentSelectedTags,
            [locationState.tag]: true,
          }
        }
      })
      locationState.tag = undefined
    }

    if (locationState?.category) {
      setSelectedCategory(locationState.category)
    }
  }, [locationState?.tag, locationState?.category, locationState])

  return (
    <Layout
      variant="secondary"
      buttonVariant="primary"
      page={TEMPLATE_NAME}
      pathname="/products"
    >
      <main className="products-page">
        <section className="products-page__landing-section">
          <div className="container">
            <PageHeading>{landingSection.heading}</PageHeading>
            <PageSubheading>{landingSection.caption}</PageSubheading>
          </div>
        </section>

        <section className="products-page__list-section">
          <div className="products-page__list-section-categories">
            <div className="container">
              <div>
                <div
                  role="button"
                  onClick={() => setSelectedCategory('all')}
                  className={classNameMap({
                    selected: selectedCategory === 'all',
                  })}
                >
                  {strings.allCategory}
                  <span>{products.list.length}</span>
                </div>
                {categoriesItems}
              </div>
            </div>
          </div>

          <div className="products-page__list-section-tags">
            <div className="container">
              <div>
                <div>
                  <span>{strings.tagsLabel}:</span>
                  {tagsToDisplay.map(tag => (
                    <Tag
                      selected={selectedTags[tag.identifier]}
                      key={tag.identifier}
                      onClick={() =>
                        setSelectedTags(current =>
                          current[tag.identifier]
                            ? { ...omit(current, [tag.identifier]) }
                            : { ...current, [tag.identifier]: true }
                        )
                      }
                    >
                      {tag.title}
                    </Tag>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="container">
              <div className="products-page__list-section-grid">
                {filteredProducts.map(product => (
                  <ProductTile
                    key={product.identifier}
                    product={product}
                    selectedTags={selectedTags}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
})

export default ProductsPage
